import React, { useState } from 'react';

import { FooterPanel, QuestionsInformationButtonsArea } from './styles';

import ArrowRight from 'img/arrow-right-white.svg';
import ArrowLeft from 'img/arrow-left-white.svg';
import textAlignLeftWhite from 'img/text-align-left-white.svg';
import textAlignLeftGray from 'img/text-align-left-gray.svg';
import playWhite from 'img/play-white.svg';
import playGray from 'img/play-gray.svg';

import { Button } from 'pages/lectures/styles';
import { QuestionType } from 'pages/lectures/utils/types';

import VideoTypeModal from 'pages/course/components/class/lecture/components/VideoTypeModal.js';
import LectureVideoQuestion from 'pages/course/components/class/lecture/LectureVideoQuestion.component.js';
import LectureTextQuestion from 'pages/course/components/class/lecture/LectureTextQuestion.component.js';

export function LecturesFooterPanel({
  questionSelected,
  course,
  isReview,
  showResolution,
  handleSelectNextQuestion,
  handleSelectPreviewQuestion,
  currentLecture,
  handleFinishLecture,
}: LecturesFooterPanelProps) {
  const [questionVideoStart, setQuestionVideoStart] = useState(false);
  const [questionTextShow, setQuestionTextShow] = useState(false);
  const [modalVideoOption, setModalVideoOption] = useState(false);
  const [LSBVideo, setLSBVideo] = useState(false);

  const screenWidth = window.innerWidth;

  const disabledPreviewButton = questionSelected?.pivot?.ordem === 1;
  
  const isLastQuestion =
    currentLecture?.findIndex(
      (question: QuestionType) => question.id_questao === questionSelected.id_questao
    ) ===
    currentLecture?.length - 1;

  const unansweredQuestionsCount = currentLecture?.filter(
    (question: QuestionType) => !question.answereds.length
  ).length;

  const isCurrentQuestionOnlyUnanswered =
    unansweredQuestionsCount === 1 &&
    currentLecture?.some(
      (question: QuestionType) => question.id_questao === questionSelected.id_questao
    );

  const disabledNextButton = isLastQuestion || isCurrentQuestionOnlyUnanswered;

  const handlePlayVideo = () => {
    if (course?.is_libras && questionSelected?.video_embed_libras) {
      setLSBVideo(true);
      setQuestionVideoStart(true);
    } else {
      setQuestionVideoStart(true);
    }
  };

  return (
    <FooterPanel data-testid="lecturesfooterpanel-footerpanel">
      <Button
        disabled={disabledPreviewButton}
        variant="invisible"
        width={screenWidth <= 768 ? '100%' : '130px'}
        onClick={handleSelectPreviewQuestion}
        style={{ display: screenWidth <= 768 ? 'none' : 'flex' }}
        data-testid="lecturesfooterpanel-prev-button-desktop"
      >
        <img src={ArrowLeft} alt="Anterior" data-testid="lecturesfooterpanel-arrowleft-desktop" />
        Anterior
      </Button>
      {showResolution && (
        <QuestionsInformationButtonsArea data-testid="lecturesfooterpanel-questionsinfo-area">
          <Button
            variant={questionTextShow ? 'primary' : 'invisible'}
            width={screenWidth <= 768 ? '100%' : '220px'}
            onClick={() => setQuestionTextShow(true)}
            disabled={!questionSelected.questao_comentada}
            data-testid="lecturesfooterpanel-readresolution-button"
          >
            <img
              src={questionTextShow ? textAlignLeftWhite : textAlignLeftGray}
              alt="Ler a resolução"
              data-testid="lecturesfooterpanel-readresolution-icon"
            />
            Ler a resolução
          </Button>
          <Button
            variant={questionVideoStart ? 'primary' : 'invisible'}
            width={screenWidth <= 768 ? '100%' : '220px'}
            onClick={handlePlayVideo}
            disabled={
              !questionSelected.video_embed ||
              (course?.is_libras && !questionSelected.video_embed_libras)
            }
            data-testid="lecturesfooterpanel-watchresolution-button"
          >
            <img
              src={questionVideoStart ? playWhite : playGray}
              alt="Assistir a resolução"
              data-testid="lecturesfooterpanel-watchresolution-icon"
            />
            Assistir a resolução
          </Button>
        </QuestionsInformationButtonsArea>
      )}
      <Button
        disabled={disabledPreviewButton}
        variant="invisible"
        width={screenWidth <= 768 ? '100%' : '130px'}
        onClick={handleSelectPreviewQuestion}
        style={{ display: screenWidth <= 768 ? 'flex' : 'none' }}
        data-testid="lecturesfooterpanel-prev-button-mobile"
      >
        <img src={ArrowLeft} alt="Anterior" data-testid="lecturesfooterpanel-arrowleft-mobile" />
        Anterior
      </Button>
      <Button
        variant="primary"
        width={screenWidth <= 768 ? '100%' : '130px'}
        disabled={isReview ? disabledNextButton : false}
        onClick={
          !isReview
            ? disabledNextButton
              ? handleFinishLecture
              : handleSelectNextQuestion
            : handleSelectNextQuestion
        }
        data-testid="lecturesfooterpanel-next-button"
      >
        {!disabledNextButton || isReview ? 'Próximo' : 'Finalizar'}
        <img src={ArrowRight} alt="Próximo" data-testid="lecturesfooterpanel-arrowright" />
      </Button>
      {modalVideoOption && (
        <VideoTypeModal
          modalVideoOption={modalVideoOption}
          setModalVideoOption={setModalVideoOption}
          setLSBVideo={setLSBVideo}
          setQuestionVideoStart={setQuestionVideoStart}
        />
      )}
      <LectureVideoQuestion
        questionVideoStart={questionVideoStart}
        setQuestionVideoStart={setQuestionVideoStart}
        videoEmbed={
          questionSelected &&
          (LSBVideo ? questionSelected.video_embed_libras : questionSelected.video_embed)
        }
        setModalVideoOption={setModalVideoOption}
        setLSBVideo={setLSBVideo}
      />
      <LectureTextQuestion
        questionTextShow={questionTextShow}
        setQuestionTextShow={setQuestionTextShow}
        text={questionSelected?.questao_comentada}
      />
    </FooterPanel>
  );
}

type LecturesFooterPanelProps = {
  questionSelected: QuestionType;
  course: any;
  isReview: boolean;
  showResolution?: boolean;
  handleSelectNextQuestion: () => void;
  handleSelectPreviewQuestion: () => void;
  currentLecture: QuestionType[];
  handleFinishLecture: () => void;
};
