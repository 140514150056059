import styled from 'styled-components';

import checkboxAnswer from 'img/checkbox-answer.svg';
import checkboxAnswerChecked from 'img/checkbox-answer-checked.svg';

export const LectureQuestionsArea = styled.div`
  display: flex;
  padding: 20px var(--spacing-6, 24px);
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  flex: 1 0 0;
  align-self: stretch;

  @media screen and (max-width: 768px) {
    padding: 0;
    margin-top: 24px;

    overflow-x: hidden;
    overflow-y: auto;
    margin: 0 -30px;
    padding: 0 30px;
  }
`;

export const SelectedAnswer = styled.label`
  width: 100%;

  > span {
    width: 100%;
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    border: 2px solid var(--escala-de-cinza-5, #f9f9fb);
    border-radius: 8px;
    background: var(--escala-de-cinza-5, #f9f9fb);

    cursor: pointer;

    &.corrected {
      border: 2px solid var(--Mensagens-Success, #62cf7a);
      background: #f3fef5;

      > .checkbox-answer {
        width: 30px;
        height: 30px;
        background-image: none;

        &::after {
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          background-color: var(--Mensagens-Success, #62cf7a);
        }
      }
    }

    > .checkbox-answer {
      width: 30px;
      height: 30px;
      min-width: 30px;
      min-height: 30px;

      background-image: url(${checkboxAnswer});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
    }
  }

  > input:checked + span:not(.corrected) {
    border: 2px solid var(--Primary-Purple, #5768ff);
    background: var(--Secondary-Lils-claro, #f0f2ff);

    > .checkbox-answer {
      background-image: url(${checkboxAnswerChecked});
    }
  }

  > input:disabled + span {
    cursor: default;
  }
`;
