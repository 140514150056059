import React, { useState, useEffect } from 'react';
import ReactCalendar from 'react-calendar';
import { format, differenceInDays } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as CalendarSVG } from '../../../../../img/performance/calendar.svg';
import Button from '../../../../../components/Button';
import Alert from '../../../../../components/ModalAlert';

import { useCourseDetail } from '../../../../../hooks/useCourseDetail';
import { actions as studentActions } from '../../../../../redux/modules/student';
import { actions as courseActions } from '../../../../../redux/modules/course';

import {   StudentStateProps, PayloadParams } from './types';

import { Title } from '../Layout/styles';
import { Container } from './styles';


function parseUserDate (date: string) {
    const parse = new Date(date)
    const formattedDate = parse.setHours(parse.getHours() + 3);

    return new Date(formattedDate);
};

export default function Calendar () {
    const [date, setDate] = useState<Date | null>(null);
    const [formattedDate, setFormattedDate] = useState('');
    const [diffDate, setDiffDate] = useState(0);
    const [editMode, setEditMode] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);
    const [errorMessage, setErrorMessage] = useState('Por favor, selecione uma data antes de continuar.');

    const { course } = useCourseDetail(); 

    const { isLoadingScheduledTest } = useSelector((state: StudentStateProps) => state.student);


    const dispatch = useDispatch();

    function handleSelect () {
        if (!date) return setIsInvalid(true);
        const rawDate = new Date(date);
        
        if (!(rawDate >= new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`))) {
            setIsInvalid(true);
            setErrorMessage('A data informada deve ser superior ou igual a data de hoje.');
            return;
        }

        const formattedDate = format(rawDate, 'yyyy-MM-dd');
        const formData = {
            date_exam: formattedDate,
            id_course: course.id,
            id_course_class: course.id_turma
        };

        setDate(parseUserDate(formData.date_exam));
        dispatch(studentActions.saveScheduledTest(formData, (payload: PayloadParams) => {
           setEditMode(false); 
           dispatch(courseActions.updateScheduleTest(payload.date_exam));
        }));
    };

    function handleEdit () {
        setEditMode(true);
        if (course.scheduledTest?.date_exam) {
            setDate(parseUserDate(course.scheduledTest?.date_exam));
        }
    }

    function handleCancel () {
        setEditMode(false);
        setDate(null);
    };

    useEffect(() => {
        if (course?.scheduledTest?.date_exam) {
            setDate(parseUserDate(course?.scheduledTest?.date_exam));
        }
        // eslint-disable-next-line
    }, [course?.scheduledTest?.date_exam]);

    useEffect(() => {
        if (date) {
            const formatted = date.toLocaleDateString('pt-BR', {
                day: '2-digit',
                month: 'long',
                year: 'numeric'
            });
    
            setFormattedDate(formatted.toString());
            setDiffDate(differenceInDays(date, new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`)));
        }
    }, [date]);

    return (
      <>
        <Alert isVisible={isInvalid} setVisible={setIsInvalid} title={errorMessage} zIndex={999} />
        <Container>
          <div className="box-title">
            <CalendarSVG />
            <Title>Data da prova</Title>
          </div>
          {(!course?.scheduledTest?.date_exam || editMode) && <p>Para quando marcou sua prova ?</p>}
          {course?.scheduledTest?.date_exam && !editMode ? (
            <div className="box-scheduled-test">
              {diffDate > 0 && (
                <h1 className="box-scheduled-test__title">
                  {diffDate === 1
                    ? `Falta ${diffDate} dia para sua prova ${course?.course_category ?? ''}`
                    : `Faltam ${diffDate} dias para sua prova ${course?.course_category ?? ''}`}
                </h1>
              )}

              <div className="box-scheduled-content">
                <div className="box-scheduled-test-information">
                  <CalendarSVG width="60" height="60" />

                  <span>Prova marcada para:</span>
                  <span className="date">{formattedDate}</span>
                </div>
              </div>

              <div className="box-button">
                <Button layout="warning" onClick={handleEdit} isLoading={isLoadingScheduledTest}>
                  Alterar data
                </Button>
              </div>
            </div>
          ) : (
            <>
              <div className="box-calendar">
                <div className="box-calendar-content">
                  <ReactCalendar onChange={setDate} value={date} locale="pt-BR" />
                </div>
              </div>
              <div className="box-footer">
                <Button layout="warning" onClick={handleSelect} isLoading={isLoadingScheduledTest}>
                  Selecionar
                </Button>
                {course?.scheduledTest?.date_exam && editMode && (
                  <Button onClick={handleCancel}>Cancelar</Button>
                )}
              </div>
            </>
          )}
        </Container>
      </>
    );
};