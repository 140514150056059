import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import * as authSelectors from 'redux/selectors/auth';
import * as classSelectors from 'redux/selectors/class';
import { actions as classActions } from 'redux/modules/class';
import { actions as courseActions } from 'redux/modules/course';
import { connect } from 'react-redux';
import MainLayout from 'components/MainLayout';
import {
  Button,
  Container,
  FinishLectureMessage,
  Header,
  IconButton,
  PanelArea,
  Title,
} from './styles';
import times from 'img/times.svg';
import arrowPrev from 'img/arrowPrev.svg';
import Loading from 'components/Loading';
import {
  CourseData,
  LectureAnswred,
  LectureType,
  LessonLectureGoingType,
  QuestionType,
  UserProfileType,
} from './utils/types';
import { Dispatch } from 'redux';
import { Instructions } from './components/Instructions';
import playWhite from 'img/play-white.svg';
import stopWhite from 'img/stop-white.svg';
import infomationIcon from 'img/information-icon.svg';
import trophyIcon from 'img/trophy-finish-lecture.svg';
import _ from 'lodash';
import { format } from 'date-fns';
import { ModalInformation, ModalInformationProps } from 'pages/course/components/ModalInformation';
import { ItemSelectOnReview, itemsSelectOnReview } from './utils';
import { LecturesQuestions } from './components/LecturesQuestions';
import { LecturesFooterPanel } from './components/LecturesFooterPanel';
import { LecturesHeaderPanel } from './components/LecturesHeaderPanel';

function Lectures(props: LecturesComponentType) {
  const screenWidth = window.innerWidth;
  const [lectureStarted, setLectureStarted] = useState(false);
  const [lecture, setLecture] = useState({} as LectureType);
  const [questionSelected, setQuestionSelected] = useState({} as QuestionType);
  const [time, setTime] = useState(0);
  const [lectureAnswers, setLectureAnswers] = useState({} as LessonLectureGoingType);
  const [widthComponentQuestionsSelectArea, setWidthComponentQuestionsSelectArea] = useState({
    scrollWidth: 0,
    clientWidth: 0,
    scrollLeft: 0,
  });
  const [reviewLecture, setReviewLecture] = useState({
    isReview: false,
    testId: '',
  });
  const [modalInformation, setModalInformation] = useState<ModalInformationProps>({
    open: false,
    icon: <></>,
    title: '',
  });
  const [reviewType, setReviewType] = useState<ItemSelectOnReview>(
    itemsSelectOnReview[0] as ItemSelectOnReview
  );
  const [testReviewTime, setTestReviewTime] = useState('');
  const [isSimulate, setIsSimulate] = useState(false);
  const [showResolution, setShowResolution] = useState(false);
  const pauseTime = useRef(false);

  const componentQuestionsSelectArea = document.getElementById('select-questions-area');
  const {
    location: { state },
    match: { params },
    getLectureDetails,
    getOngoingLecture,
    persistOngoingLecture,
    user,
    isLoading,
    history,
    finishLecture,
    getLectureWrongQuestions,
  } = props as any;
  const { selectedClass, course } = state ? state : { selectedClass: {}, course: {} };

  const formatTimeLong = (time: string) => {
    const hours = time.split(':')[0];
    const minutes = time.split(':')[1];
    const seconds = time.split(':')[2];

    const stringTime = `${hours.padStart(2, '0')} horas ${minutes.padStart(
      2,
      '0'
    )} min e ${seconds.padStart(2, '0')} seg`;
    return stringTime;
  };

  useEffect(() => {
    if (params?.testId) {
      setReviewLecture({ isReview: true, testId: params.testId });

      getLectureDetails(selectedClass.id, (apiLecture: LectureType) => {
        getLectureWrongQuestions(params.testId, (answredLecture: LectureAnswred[]) => {
          if (answredLecture.length > 0) {
            const stringTime = formatTimeLong(answredLecture[0]?.tempo_prova);
            setTestReviewTime(stringTime);
          }

          const lectureWithCorrectAnswers = { ...apiLecture };
          lectureWithCorrectAnswers.questions.map((question, index) => {
            question.answers = answredLecture[index].questao.respostas;
            return question;
          });
          setQuestionSelected(lectureWithCorrectAnswers?.questions[0]);
          setLecture(lectureWithCorrectAnswers);
          setLectureStarted(true);
        });
      });
    } else {
      getLectureDetails(selectedClass.id, (apiLecture: LectureType) => {
        setQuestionSelected(apiLecture?.questions[0]);
        setLecture(apiLecture);

        setIsSimulate(
          apiLecture.class_type?.slug === 'simulado' ||
            apiLecture.class_type?.slug === 'simuladocomvideo'
        );
        setShowResolution(apiLecture.class_type?.slug !== 'simulado');

        getOngoingLecture(user.id, selectedClass.id, course.tag, (lectureGoing: CourseData[]) => {
          const apiLectureAnswers = JSON.parse(
            _.get(lectureGoing, '[0].prova', {})
          ) as LessonLectureGoingType;
          setLectureAnswers(apiLectureAnswers);
        });
      });
    }
    //eslint-disable-next-line
  }, [params?.testId, selectedClass, course]);

  useEffect(() => {
    if (componentQuestionsSelectArea) {
      setWidthComponentQuestionsSelectArea({
        scrollWidth: componentQuestionsSelectArea.scrollWidth,
        clientWidth: componentQuestionsSelectArea.clientWidth,
        scrollLeft: 0,
      });
    }
  }, [componentQuestionsSelectArea]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (pauseTime.current) return;
      setTime((prevTime) => prevTime + 1);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const getCorrectAnswer = (questions: QuestionType[]) => {
    return questions?.filter((question) => {
      const getAnswerCorrect = question.answers.find((answer) => answer.correta === 1);
      const getAnswerAnswred = question.answers.find((answer) => answer.marcada === true);

      return getAnswerCorrect?.id_resposta === getAnswerAnswred?.id_resposta;
    });
  };

  const getWrongQuestions = (questions: QuestionType[]) => {
    return questions?.filter((question) => {
      const getAnswerCorrect = question.answers.find((answer) => answer.correta === 1);
      const getAnswerAnswred = question.answers.find((answer) => answer.marcada === true);

      return getAnswerCorrect?.id_resposta !== getAnswerAnswred?.id_resposta;
    });
  };

  const questionsReviewCorrectedWrong = () => {
    if (reviewType.value === 'correct') {
      return getCorrectAnswer(lecture?.questions);
    }

    if (reviewType.value === 'wrong') {
      return getWrongQuestions(lecture?.questions);
    }

    return lecture?.questions;
  };

  const currentLecture = useMemo(() => {
    const current = questionsReviewCorrectedWrong();
    if (current?.length > 0) setQuestionSelected(current[0]);
    return current;
    //eslint-disable-next-line
  }, [reviewType, lecture?.questions]);

  const countQuantityCountCorrectQuestions = () => {
    return {
      correctQuestions: getCorrectAnswer(lecture?.questions).length,
      totalQuestions: lecture?.questions.length,
    };
  };

  const handleChangeItemReview = (e: ChangeEvent<HTMLSelectElement>) => {
    const item = itemsSelectOnReview.find((item) => item.title === e.target.value);
    if (item) setReviewType(item as ItemSelectOnReview);
  };

  const handleViewNextsQuestions = () => {
    const { scrollWidth, clientWidth, scrollLeft } = widthComponentQuestionsSelectArea;
    const scrollLeftValue = scrollLeft + 300;
    componentQuestionsSelectArea?.scrollTo({ left: scrollLeftValue, behavior: 'smooth' });
    setWidthComponentQuestionsSelectArea({
      scrollWidth,
      clientWidth,
      scrollLeft: scrollLeftValue,
    });
  };

  const handleViewPreviewQuestions = () => {
    const { scrollWidth, clientWidth, scrollLeft } = widthComponentQuestionsSelectArea;
    const scrollLeftValue = scrollLeft - 300;
    componentQuestionsSelectArea?.scrollTo({ left: scrollLeftValue, behavior: 'smooth' });
    setWidthComponentQuestionsSelectArea({
      scrollWidth,
      clientWidth,
      scrollLeft: scrollLeftValue,
    });
  };

  const formatTime = (seconds: number) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs
      .toString()
      .padStart(2, '0')}`;
  };

  const handleStartLecture = async () => {
    setLectureStarted(true);
    setTime(0);
  };

  const handleSelectQuestion = (questionId: string) => {
    const question = currentLecture?.find(
      (question: QuestionType) => question.id_questao === Number(questionId)
    );
    if (question) {
      setQuestionSelected(question);
    }
  };

  const createObjectOfQuestionsAndAnswersLetters = () => {
    const lettersArray = ['A', 'B', 'C', 'D', 'E'];

    return lecture?.questions?.map((question) => {
      const indexLetter = question?.answers?.findIndex(
        (answer) => answer.id_resposta === lectureAnswers?.question[question.id_questao]
      );
      const answer = lectureAnswers?.question[question.id_questao];
      const letter = lettersArray[indexLetter];
      return { questionId: question.id_questao, answer, letter };
    });
  };

  const handleCloseModalInformations = () => {
    setModalInformation({ open: false, icon: <></>, title: '' });
  };

  const handleBackToLesson = () => {
    history.push(`/courses/${params?.courseId}/${params?.courseClassId}/${selectedClass?.id}`);
  };

  const handleRequestOutLecture = () => {
    if (!lectureStarted || reviewLecture.isReview) {
      handleBackToLesson();
    } else {
      setModalInformation({
        open: true,
        icon: <img src={infomationIcon} alt="Alerta" className="Alerta" />,
        iconPosition: 'left',
        title: 'Você tem certeza que gostaria de sair da prova?',
        primaryButtonText: 'Sim, quero sair',
        primaryButtonAction: () => handleBackToLesson(),
        secondaryButtonText: 'Não, quero ficar',
        secondaryButtonAction: handleCloseModalInformations,
        onclose: handleCloseModalInformations,
      });
    }
  };

  const verifyIfAllQuestionsAnswered = () => {
    const hasLecturesAnswers = Object.keys(lectureAnswers).length > 0;
    const allQuestionsAnswered = currentLecture?.every((question) =>
      hasLecturesAnswers
        ? lectureAnswers?.question[question.id_questao] !== undefined
        : question?.answers.some((answer) => answer?.marcada)
    );

    if (!allQuestionsAnswered) {
      setModalInformation({
        open: true,
        icon: <img src={infomationIcon} alt="Alerta" className="Alerta" />,
        iconPosition: 'left',
        title: 'Você precisa responder todas as questões antes de finalizar a prova!',
        primaryButtonText: 'Ver questões não respondidas',
        primaryButtonAction: handleGetFirstQuestionUnanswered,
        secondaryButtonText: 'Cancelar',
        secondaryButtonAction: handleCloseModalInformations,
        onclose: handleCloseModalInformations,
      });
    }

    return allQuestionsAnswered;
  };

  const handleGetFirstQuestionUnanswered = () => {
    const hasLecturesAnswers = Object.keys(lectureAnswers).length > 0;
    const firstQuestionUnanswered = currentLecture?.find((question) =>
      hasLecturesAnswers
        ? lectureAnswers?.question[question.id_questao] === undefined
        : !question?.answers.some((answer) => answer?.marcada)
    );

    if (firstQuestionUnanswered) {
      setQuestionSelected(firstQuestionUnanswered);
    }

    handleCloseModalInformations();
  };

  const handleFinishLecture = async () => {
    if (!verifyIfAllQuestionsAnswered()) return;
    const answersWithLetters = createObjectOfQuestionsAndAnswersLetters();
    const finalPayload = {
      prova: {
        contador: formatTime(time),
        curso: course?.tag,
        aula: selectedClass.id,
        question: lectureAnswers.question,
        answersWithLetters,
        data_prova: format(new Date(), 'yyyy/MM/dd HH:mm'),
      },
    };

    await finishLecture(
      finalPayload,
      user.id,
      selectedClass.id,
      (finishLecture: { finishedLecture: { id_prova: number } }) => {
        const lectureId = finishLecture.finishedLecture.id_prova;

        stopStartTimer();

        getLectureWrongQuestions(lectureId, (answredLecture: LectureAnswred[]) => {
          let stringTime = '';
          if (answredLecture.length > 0) {
            stringTime = formatTimeLong(answredLecture[0]?.tempo_prova);
          }

          const lectureWithCorrectAnswers = { ...lecture };
          lectureWithCorrectAnswers.questions.map((question, index) => {
            question.answers = answredLecture[index].questao.respostas;
            return question;
          });
          setQuestionSelected(lectureWithCorrectAnswers?.questions[0]);
          setLecture(lectureWithCorrectAnswers);

          setModalInformation({
            icon: <img src={trophyIcon} alt="Alerta" className="Alerta" width={46} height={46} />,
            iconPosition: 'center',
            open: true,
            title: (
              <FinishLectureMessage>
                Você acertou{' '}
                <span>
                  {countQuantityCountCorrectQuestions().correctQuestions} de{' '}
                  {lecture?.questions?.length}
                </span>{' '}
                questões em <span>{stringTime}</span>!
              </FinishLectureMessage>
            ),
            primaryButtonText: 'Ver a prova corrigida >',
            primaryButtonAction: () => {
              handleCloseModalInformations();
              history.push(`/lectures/${params?.courseId}/${params?.courseClassId}/${lectureId}`, {
                selectedClass,
                course,
              });
            },
          });
        });
      }
    );
  };

  const handleSelectNextQuestion = () => {
    let nextQuestionIndex;

    if (reviewLecture.isReview) {
      nextQuestionIndex =
        currentLecture?.findIndex(
          (question: QuestionType) => question.id_questao === questionSelected.id_questao
        ) + 1;
    } else {
      const _index = currentLecture?.findIndex(
        (question: QuestionType) =>
          !question.answereds.length &&
          question.id_questao !== questionSelected.id_questao &&
          currentLecture.indexOf(question) > currentLecture.indexOf(questionSelected)
      );

      nextQuestionIndex =
        _index >= 0
          ? _index
          : currentLecture?.findIndex(
              (question: QuestionType) => question.id_questao === questionSelected.id_questao
            ) + 1;
    }

    if (nextQuestionIndex) {
      const questionId = currentLecture[nextQuestionIndex].id_questao.toString();
      handleSelectQuestion(questionId);
    }
  };

  const handleSelectPreviewQuestion = () => {
    const previewQuestionIndex =
      currentLecture?.findIndex(
        (question: QuestionType) => question.id_questao === questionSelected.id_questao
      ) - 1;
    if (previewQuestionIndex > -1) {
      const questionId = currentLecture[previewQuestionIndex].id_questao.toString();
      handleSelectQuestion(questionId);
    }
  };

  const stopStartTimer = () => (pauseTime.current = !pauseTime.current);

  const removeIdkValues = (data: LessonLectureGoingType): LessonLectureGoingType => {
    const updatedQuestionTime = { ...data.question_time };
    const updatedQuestion = { ...data.question };

    for (const key in data.question) {
      if (data.question[key] === 'idk') {
        delete updatedQuestionTime[key];
        delete updatedQuestion[key];
      }
    }

    return {
      ...data,
      question_time: updatedQuestionTime,
      question: updatedQuestion,
    };
  };

  const handleAnswersQuestion = async (answerId: string) => {
    const newLectureAnswers: LessonLectureGoingType = _.isEmpty(lectureAnswers)
      ? {
          contador: '00:00:00',
          curso: course?.tag,
          aula: lecture?.id_aula,
          question_time: {},
          question: {},
        }
      : { ...lectureAnswers };

    const findIfQuestionAlreadyAnswered = Object.keys(newLectureAnswers.question).find(
      (questionId) => questionId === questionSelected.id_questao.toString()
    );

    if (findIfQuestionAlreadyAnswered) {
      newLectureAnswers.question[questionSelected.id_questao] =
        answerId === 'idk' ? answerId : Number(answerId);
      newLectureAnswers.question_time[questionSelected.id_questao] = formatTime(time);
    } else {
      newLectureAnswers.question = {
        ...newLectureAnswers.question,
        [questionSelected.id_questao]: answerId === 'idk' ? answerId : Number(answerId),
      };
      newLectureAnswers.question_time = {
        ...newLectureAnswers.question_time,
        [questionSelected.id_questao]: formatTime(time),
      };
    }
    newLectureAnswers.contador = formatTime(time);

    setLectureAnswers(newLectureAnswers);

    const sendValues = removeIdkValues(newLectureAnswers);

    await persistOngoingLecture(sendValues, user.id, selectedClass.id);
  };

  const checkedAnswersDontKnow =
    Object.keys(lectureAnswers).length > 0
      ? lectureAnswers.question[questionSelected.id_questao] === 'idk'
      : false;

  return (
    <MainLayout title={lecture?.titulo} className="home">
      {isLoading || !lecture ? (
        <Container>
          <Loading />
        </Container>
      ) : (
        <Container>
          <Header>
            {screenWidth <= 768 ? (
              <IconButton onClick={handleRequestOutLecture}>
                <img src={arrowPrev} alt="sair" />
              </IconButton>
            ) : (
              <Button width="110px" variant="invisible" onClick={handleRequestOutLecture}>
                <img src={times} alt="Sair" />
                Sair
              </Button>
            )}

            <Title margin={!lectureStarted || reviewLecture.isReview ? '0px 110px 0px 0px' : '0px'}>
              {reviewLecture.isReview
                ? `Revisão ${lecture?.titulo} - ${course?.title}`
                : !lectureStarted || !isSimulate
                ? `${lecture?.titulo} - ${course?.title}`
                : formatTime(time)}
            </Title>

            {lectureStarted && !reviewLecture.isReview && isSimulate && (
              <Button width="110px" variant="danger" onClick={stopStartTimer}>
                {pauseTime.current ? (
                  <>
                    <img src={playWhite} alt="Parar" />
                    Continuar
                  </>
                ) : (
                  <>
                    <img src={stopWhite} alt="Parar" />
                    Pausar
                  </>
                )}
              </Button>
            )}
          </Header>
          {!lectureStarted ? (
            <Instructions handleStartLecture={handleStartLecture} lecture={lecture} />
          ) : (
            <PanelArea>
              <LecturesHeaderPanel
                currentLecture={currentLecture}
                handleSelectQuestion={handleSelectQuestion}
                handleViewNextsQuestions={handleViewNextsQuestions}
                handleViewPreviewQuestions={handleViewPreviewQuestions}
                reviewType={reviewType}
                handleChangeItemReview={handleChangeItemReview}
                itemsSelectOnReview={itemsSelectOnReview}
                lecture={lecture}
                lectureAnswers={lectureAnswers}
                questionSelected={questionSelected}
                testReviewTime={testReviewTime}
                countQuantityCountCorrectQuestions={countQuantityCountCorrectQuestions}
                widthComponentQuestionsSelectArea={widthComponentQuestionsSelectArea}
                isReview={reviewLecture.isReview}
              />
              <LecturesQuestions
                checkedAnswersDontKnow={checkedAnswersDontKnow}
                currentLecture={currentLecture}
                handleAnswersQuestion={handleAnswersQuestion}
                lectureAnswers={lectureAnswers}
                questionSelected={questionSelected}
                reviewLecture={reviewLecture}
              />
              <LecturesFooterPanel
                course={course}
                currentLecture={currentLecture}
                handleFinishLecture={handleFinishLecture}
                handleSelectNextQuestion={handleSelectNextQuestion}
                handleSelectPreviewQuestion={handleSelectPreviewQuestion}
                isReview={reviewLecture.isReview}
                showResolution={showResolution}
                questionSelected={questionSelected}
              />
            </PanelArea>
          )}

          <ModalInformation
            icon={modalInformation.icon}
            iconPosition={modalInformation.iconPosition}
            open={modalInformation.open}
            primaryButtonAction={modalInformation.primaryButtonAction}
            primaryButtonText={modalInformation.primaryButtonText}
            secondaryButtonAction={modalInformation.secondaryButtonAction}
            secondaryButtonText={modalInformation.secondaryButtonText}
            title={modalInformation.title}
            onclose={modalInformation.onclose}
          />
        </Container>
      )}
    </MainLayout>
  );
}

const mapStateToProps = (state: AppState) => ({
  studentQuestions: classSelectors.getStudentQuestions(state),
  isLoading: classSelectors.isLoading(state),
  user: authSelectors.getUser(state),
  ongoingLecture: classSelectors.getOngoingLecture(state),
  lectureWrongQuestions: classSelectors.lectureWrongQuestions(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  getLectureDetails: (classId, next) => dispatch(classActions.getLectureDetails(classId, next)),
  persistOngoingLecture: (ongoingPayload, userId, classId, next) =>
    dispatch(classActions.persistOngoingLecture(ongoingPayload, userId, classId, next)),
  getOngoingLecture: (userId, classId, courseTag, next) =>
    dispatch(classActions.getOngoingLecture(userId, classId, courseTag, next)),
  finishLecture: (finalPayload, userId, classId, next) =>
    dispatch(classActions.finishLecture(finalPayload, userId, classId, next)),
  getCourseDetails: (courseId, courseClassId: null | undefined, next) =>
    dispatch(courseActions.getCourseDetails(courseId, courseClassId, next)),
  getLectureWrongQuestions: (lectureId, next) =>
    dispatch(classActions.getLectureWrongQuestions(lectureId, next)),
  getFinishedLectures: (classId, next) => dispatch(classActions.getFinishedLectures(classId, next)),
  setClassProperties: (courseId, step, next) =>
    dispatch(courseActions.setClassProperties(courseId, step, next)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Lectures);

type DispatchProps = {
  getLectureDetails: (classId: string, next: () => void) => void;
  persistOngoingLecture: (
    ongoingPayload: any,
    userId: string,
    classId: string,
    next: () => void
  ) => void;
  getOngoingLecture: (userId: string, classId: string, courseTag: string, next: () => void) => void;
  finishLecture: (finalPayload: any, userId: string, classId: string, next: () => void) => void;
  getCourseDetails: (courseId: string, courseClassId: null | undefined, next: () => void) => void;
  getLectureWrongQuestions: (lectureId: string, next: () => void) => void;
  getFinishedLectures: (classId: string, next: () => void) => void;
  setClassProperties: (courseId: string, step: any, next: () => void) => void;
};

type AppState = {
  studentQuestions: QuestionType[];
  isLoading: boolean;
  user: UserProfileType;
  ongoingLecture: LectureType;
  lectureWrongQuestions: QuestionType[];
};

type LecturesComponentType = DispatchProps & AppState;
